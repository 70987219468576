import React from 'react';
import { BrowserRouter } from "react-router-dom";

import AppHeader from "../components/header/AppHeader";
import Footer from "../components/footer/Footer";

function App() {
  return (
      <BrowserRouter>
          <div className={'bg-fixed bg-center bg-cover h-screen flex flex-row md:h-auto'}>
              <div className={'flex flex-col h-screen w-screen'} style={{background: 'rgba(255,255,255)'}}>
                  <AppHeader/>
                  <Footer/>
              </div>
          </div>
      </BrowserRouter>
  );
}

export default App;
