import ICLocation from "../../images/footer/ic_location_light.png";
import ICCall from "../../images/footer/ic_call_light.png"
import ICMail from "../../images/footer/ic_email_light.png"
import {Link} from "react-router-dom";
import { appImages } from "../../utils/appImages";
import scrollToItem from "../../utils/uiActions";
import SectionDividerDark from "../SectionDividerDark";

const Footer = () => {
    return (
        <div className={''}>

            <SectionDividerDark/>

            <div className={'flex flex-row justify-center pt-[20px] pb-[45px] items-center'}>
                <div className={'container'}>
{/* 
                    <div className={"grid grid-cols-2"}>

                    </div> */}


                    <div className={'flex flex-col'}>

                        <div className="items-center">
                            <img className={'w-full h-48 object-contain px-10'} src={appImages.logo_original} alt={'brand logo'}/>
                        </div>


                        <div className={'flex flex-col md:flex-row justify-between items-center  mt-10'}>

                            <div className={'md:w-[1/4] grid grid-cols-3 grid-rows-2 gap-4 md:mt-0 mt-1 mx-2 md:pl-[5%]'}>
                                <div className="row-span-1 col-span-4 flex flex-row justify-center items-center">
                                    <img className={'px-2 h-75 w-75'} src={ICLocation} alt={'location'}/>
                                    <h2 className={'text text-dark text-2xl font-bold'}>Find us</h2>
                                </div>
                                
                                <div className={'flex flex-col col-span-4 row-span-1'}>
                                    <h2 className={'text text-sm font-thin text-center'}>
                                        Dagoretti Rd,<br/> Karen
                                    </h2>
                                </div>
                            </div>

                            <HorizontalDivider className={''}/>

                            <div className={'md:w-[1/4] grid grid-cols-3 grid-rows-2 gap-4 md:mt-0 mt-1 mx-2'}>
                                <div className="row-span-1 col-span-4 flex flex-row justify-center items-center">
                                    <img className={'px-2 h-75 w-75'} src={ICCall} alt={'call'}/>
                                    <h2 className={'text text-dark text-2xl font-bold'}>Call us</h2>
                                </div>
                                
                                <div className={'flex flex-col col-span-4 row-span-1 text-center'}>
                                    <h2 className={'text text-sm font-thin'}>
                                        +254 729 329 488<br/>+254 113 576 111
                                    </h2>
                                </div>
                            </div>

                            <HorizontalDivider className={''}/>

                            <div className={'md:w-[1/4] grid grid-cols-3 grid-rows-2 gap-4 md:mt-0 mt-1 mx-2'}>
                                <div className="row-span-1 col-span-4 flex flex-row justify-center items-center">
                                    <img className={'px-2 h-75 w-75'} src={ICMail} alt={'mail'}/>
                                    <h2 className={'text text-dark text-2xl font-bold'}>Mail us</h2>
                                </div>
                                
                                <div className={'flex flex-col col-span-4 row-span-1'}>
                                    <h2 className={'text text-sm font-thin text-center break-all'}>
                                        reservations@flamingohillcamp.co.ke
                                    </h2>
                                </div>
                            </div>

                            <HorizontalDivider className={''}/>

                            <div className={'md:w-[1/4] grid grid-cols-3 grid-rows-2 gap-4 md:mt-0 mt-1 mx-2 md:pr-[5%] md:pl-[-15%]'}>
                                <div className="row-span-1 col-span-4 flex flex-row justify-center items-center">
                                <i className="fab fa-hashtag pr-4 text-dark text-2xl font-bold"></i>
                                    <h2 className={'text text-dark text-2xl font-bold'}>Socials</h2>
                                </div>

                                <ul className="col-span-4">
                                    <li>
                                        <a href="https://web.facebook.com/Flamingohillcamp/?_rdc=1&_rdr" rel="noreferrer" target="_blank" className="text-dark hover:underline">
                                            <i className="fab fa-facebook pr-4"></i> Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/flamingohillcamp/" rel="noreferrer" target="_blank" className="text-dark hover:underline">
                                            <i className="fab fa-instagram pr-4"></i> Instagram
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className={'flex flex-row h-[1px] mt-[55px] mb-[55px] bg-gray-900'}></div>

                        <div className={'w-full flex flex-col md:flex-row justify-center items-center'}>
                            <Link to={'/'} className={'text-dark font-extrathin text-2xl hover:font-extrabold mx-4'} onClick={()=>scrollToItem('navMenu')}>
                                Home
                            </Link>
                            <Link to={'/rooms'} className={'text-dark font-extrathin text-2xl hover:font-extrabold mx-4'} onClick={()=>scrollToItem('navMenu')}>
                                Our Rooms
                            </Link>
                            <Link to={'/restaurant'} className={'text-dark font-extrathin text-2xl hover:font-extrabold mx-4'} onClick={()=>scrollToItem('navMenu')}>
                                Restaurant & Bar
                            </Link>
                            <Link to={'/spa'} className={'text-dark font-extrathin text-2xl hover:font-extrabold mx-4'} onClick={()=>scrollToItem('navMenu')}>
                                Spa & Pool
                            </Link>
                            <Link to={'/gallery'} className={'text-dark font-extrathin text-2xl hover:font-extrabold mx-4'} onClick={()=>scrollToItem('navMenu')}>
                                Gallery
                            </Link>
                            <Link to={'/booking'} className={'text-dark font-extrathin text-2xl hover:font-extrabold mx-4'} onClick={()=>scrollToItem('navMenu')}>
                                Contact Us
                            </Link>
                        </div>

                    </div>

                </div>
            </div>

            <div className={'flex flex-row py-[10px] justify-center bg-white'}>
                <p className={'text text-dark text-sm'}>Copyright &copy; {new Date().getFullYear()}, All Rights Reserved</p>
            </div>
        </div>
    )
}

// horizontal divider component
const HorizontalDivider = ({className}: any) => {
    return (
        <div className={`collapse md:visible flex flex-row w-[2px] h-[100px] pt-[15px] pb-[15px] bg-gray-900 ${className}`}></div>
    )
}


export default Footer